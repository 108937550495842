import { inject, Injectable } from '@angular/core'
import { AppStore } from './../../store/AppStore'
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Router } from '@angular/router'
import { environment } from '@environments/environment'
import {
	SAMLAuthProvider,
	Auth as FirebaseAuth,
	getAuth,
	UserCredential,
	Auth,
	idToken,
	verifyPasswordResetCode,
	sendEmailVerification,
} from '@angular/fire/auth'
import { browserSessionPersistence, inMemoryPersistence, signInWithPopup, User } from 'firebase/auth'
import { NotificationsService } from '../notifications.service'
/**
 * Servicio que contiene la lógica de autenticación con OAuth2
 */
@Injectable({
	providedIn: 'root',
})
export class OAuthService {
	private appStore = inject(AppStore)
	authInstance!: Auth
	EXP_TIME_MINUTES: number = 1

	constructor(
		private router: Router,
		private authFirebase: FirebaseAuth
	) {}

	// async verifyEmail() {
	// 	this.authFirebase.currentUser
	// 	await sendEmailVerification(this.authFirebase.currentUser!)
	// }

	async azureAuth() {
		const provider = new SAMLAuthProvider(environment.firebaseConfig.SAMLAuthProvider)
		provider.setCustomParameters({
			prompt: 'select_account',
		})
		this.authInstance = getAuth()
		try {
			const res = await this.authInstance.setPersistence(browserSessionPersistence).then(async () => {
				// Autenticar al usuario
				const res: UserCredential = await signInWithPopup(this.authInstance, provider)
				return res
			})
			this.setUserData(res.user)
			this.router.navigateByUrl('/home')
		} catch (error) {
			console.error('Error durante la autenticación con Azure:', error)
			// Manejo de errores
		}
	}

	async fetchIdToken(forceRefresh: boolean = false): Promise<string | null> {
		if (!this.authFirebase.currentUser) {
			return null
		}

		try {
			const idToken = await this.authFirebase.currentUser.getIdToken(forceRefresh)
			return idToken
		} catch (error) {
			console.error('Error al obtener el ID token:', error)
			return null // Propaga el error para manejarlo donde sea necesario
		}
	}

	setUserData(user: User, idToken?: string) {
		// Aquí puedes realizar acciones adicionales con el ID token
		this.appStore.setUserSession({
			providerId: user.providerId!,
			displayName: user.displayName!,
			email: user.email!,
			uid: user.uid,
			idToken: idToken ?? (user.toJSON() as Record<string, any>)['stsTokenManager']['accessToken'],
			exp: this.getSessionExpiration(),
		})
	}

	async validateUserSignedIn(): Promise<User | null> {
		return new Promise((resolve, reject) => {
			try {
				this.authFirebase.onAuthStateChanged((user: User | null) => {
					if (!user) return resolve(null)
					this.fetchIdToken().then((token) => {
						if (token) this.setUserData(user, token)
					})
					resolve(user)
				})
			} catch (error) {
				reject(error)
			}
		})
	}

	async refreshToken() {
		const token = await this.fetchIdToken(true)
		if (token)
			this.appStore.setUserSession({
				idToken: token,
				exp: (this.authFirebase.currentUser?.toJSON() as Record<string, any>)['stsTokenManager'][
					'expirationTime'
				] as number,
			})
	}

	async logout(omitRefresh = false) {
		try {
			await this.authFirebase.signOut()
			sessionStorage.clear()
			this.appStore.signOut()
			const isSaml = this.appStore.userInfo()?.providerId?.includes('saml')
			if (isSaml) this.signoutAzure()
			if (!omitRefresh) window.location.reload()
		} catch (error) {
			console.error('Error al cerrar sesión:', error)
		}
	}

	signoutAzure() {
		// Redirige al usuario a la página de inicio después de cerrar sesión en Azure AD
		const postLogoutRedirectUri = encodeURIComponent(environment.redirectUri)

		// Redirige al usuario para cerrar la sesión en Azure AD y luego a la página de inicio
		window.location.href = `${environment.logoutUri}?post_logout_redirect_uri=${postLogoutRedirectUri}`
	}

	// Verifica si el usuario está autenticado
	isAuthenticated(): boolean {
		if (this.appStore.userInfo()?.idToken) {
			return true
		}

		return false
	}

	// Obtiene la fecha de expiración de la sesión del usuario
	getSessionExpiration(): number {
		const user = this.authFirebase.currentUser?.toJSON() as Record<string, any>
		const exp = (user['stsTokenManager']['expirationTime'] as number) || 0
		const originalTime = exp
		const timeToSubtract = this.EXP_TIME_MINUTES * 60 * 1000 // 59 minutos en milisegundos
		return originalTime - timeToSubtract
	}
}
